<template>
  <div class="contentbody"  v-loading='store._state.data.app.Loading'  element-loading-text="正在加载..." style="min-height:200px">
      <nodata v-if="getters.newsListdata.value.data&&getters.newsListdata.value.data.length<1&&!store._state.data.app.Loading" :code='getters.code' :list='[]' :flag='false' />
      <ul v-else-if='!store._state.data.app.Loading' class="news-content">
        <li v-for="item in getters.newsListdata.value.data" :key="item.id">
          <a class="contents" :href="item.jump_url"  target="_blank">
             <span class="is_top" v-if="item.is_top===1">{{t('tip.TOP')}}</span>
            <div class="img-div">
              <img :src="item.cover_img_url" alt="" />
            </div>
            <div class="title-div">
              <div class="title" :title="item.title">{{item.title}}</div>
              <div class="content">
                {{item.intro}}
              </div>
              <div class="titme">{{item.author}}&nbsp;&nbsp;&nbsp;&nbsp;{{item.release_time}}</div>
            </div>
          </a>
        </li>
      </ul>
      <div style="text-align: center;"  v-if="searchForm.current_page<getters.newsListdata.value.last_page">
        <el-button class="btn-more" @click="loadMore" v-loading='loading'>{{t('button.LoadMore')}}</el-button>
      </div>
  </div>
</template>
<script>
import { defineComponent, ref, reactive,watch } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from 'vue-i18n'
import store from "@/store";
import nodata from "@/pages/nodata.vue";
import {useGetters} from '@/store/use.js'
export default defineComponent({
  components: {nodata},
  setup() {
    const route = useRouter();
    const { t, locale } = useI18n()
     const getters=useGetters(['language','code','newsListdata'])
     const searchForm=reactive({
      per_page:10,
      current_page:1,
      language:getters.language.value=='cn'?1:2
    })
    store.dispatch("app/getnews_list",searchForm);
    const loadMore=()=>{
       searchForm.per_page+=10
      //  sessionStorage.setItem('per_page',searchForm.per_page)
       store.dispatch("app/getnews_list",searchForm);
    }
    return {
      t,
      getters,
      searchForm,
      loadMore,
      store
    };
  },
});
</script>
<style lang="scss" scoped>
.contentbody{
   margin: 50px auto;
.news-content {
  margin-top: 20px;
  li {
    height: 210px;
    margin-bottom: 30px;
    cursor: pointer;
    padding: 12px 15px;
    .contents {
      text-decoration: none;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      display: flex;
      font-family: PingFangSC-Regular, PingFang SC;
      position: relative;

      .is_top{
        position: absolute;
        top: 0px;
        width: 50px;
        height: 22px;
        font-size: 12px;
        background: #FAAD14;
        border-radius: 2px 0px 2px 0px;
        color: #FFFFFF;
        line-height: 22px;
        text-align: center;
      }
      .img-div {
        width: 300px;
        height: 180px;
        img {
          width: 300px;
          height: 180px;
          object-fit: cover;
        }
      }
      .title-div {
        margin-left: 38px;
        font-size: 15px;
        width: 842px;
        font-weight: 400;
        color: #333333;
        line-height: 25px;
        -webkit-box-orient: vertical;
        .title{
          display: block;
          overflow : hidden ;//超出一行文字自动隐藏
          text-overflow :ellipsis;//文字隐藏后添加省略号
          white-space : nowrap ;//强制不换行
            height: 20px;
            font-size: 20px;
            font-weight: 500;
            color: #333333;
            line-height: 20px;
            margin-top: 6px;
        }
        .content{
            margin-top: 22px;
            height: 69px;
            font-size: 15px;
            font-weight: 400;
            color: #999999;
            line-height: 23px;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
        }
        .titme{
           margin-top: 45px;
            height: 12px;
            font-size: 12px;
            font-weight: 400;
            color: #999999;
            line-height: 12px;
        }
      }
    }
  }
 li:hover {
     background: #FFFFFF;
    box-shadow: 0px 0px 10px 0px rgba(207, 190, 195, 0.5);
    border-radius: 2px 2px 0px 0px;
        .title{
            color: #e34c3e !important;
        }
    }
}
}
@media screen and (min-width: 375px) and (max-width: 750px){
    .contentbody{
      width: 95%;
      .news-content{
        li{
          height: 150px;
          padding: 10px 0px;
          position: relative;
          .img-div{
            width: 120px !important;
            height: 100% !important;
            img{width: 120px !important;height:100% !important}
          }
          .title-div{
           width: calc(100% - 130px) !important;
            margin-left: 15px !important;
          .content{height: 68px !important;
            margin-top: 10px !important;
            display: -webkit-box;
            word-break: break-all;
            text-overflow: ellipsis;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            padding-right: 3px;
          }
          .title{font-size: 18px !important;}
          .titme{bottom: 0px;position: absolute;margin-top: 10px !important;}
              
        }
        }
       
      }
      
    }
    .btn-more {width: 80%;}
  }
</style>
