<template>
  <div class="contentbody" style="">
    <div class="demo-button-style">
      <el-radio-group v-model="radiGroup" size="large" @change="change_radio">
        <el-radio-button
          v-for="(item,i) in tabList"
          :key="i" :label="item.id">{{getters.language.value==='cn'?item.cn_name:item.en_name }}</el-radio-button
        >
      </el-radio-group>
    </div>
    <div v-loading='store._state.data.app.Loading'  element-loading-text="正在加载..." style="min-height:200px">
      <nodata v-if="listData.length<1&&!store._state.data.app.Loading" :code='getters.code' :list='[]' :flag='false' />
      <ul v-else-if='!store._state.data.app.Loading' class="video-content">
        <li v-for="item in listData" :key="item.id">
          <div class="content" @click="go_videoDetails(item)">
              <span class="is_top" v-if="item.is_top===1">{{t('tip.TOP')}}</span>
            <img src="@/assets/images/video.png" alt=""  style="position: absolute; top: 99px;left: 169px;"/>
            <div class="img-div">
              <img :src="item.cover_img_url" alt="" />
            </div>
            <div class="title-div">
               {{getters.language.value==='cn'?item.title_cn:item.title_en}}
            </div>
          </div>
        </li>
      </ul>
      <div style="text-align: center;margin-top: 10px;" v-if="searchForm.current_page<last_page">
        <el-button class="btn-more" @click="getList(true)">{{t('button.LoadMore')}}</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, reactive } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from 'vue-i18n'
import {useGetters} from '@/store/use.js'
import nodata from "@/pages/nodata.vue";
import { Ajax, MessageTips } from '@/utils'
 import store from "@/store";
export default defineComponent({
  components: {nodata},
  setup() {
    const route = useRouter();
    const { t, locale } = useI18n()
    const getters=useGetters(['language','code'])
    const tabList=ref([])
    const radiGroup =ref('');
    const listData=ref([]),last_page=ref(1)
    const searchForm=reactive({
      per_page:9,
      current_page:1,
      awards_type:''
    })
    const go_videoDetails=(item)=>{
      if(item.is_three==1)
        window.open('/video-details?id='+item.id)
      else  window.open(item.jump_url)
      // route.push('/video-details?id='+id)
    }
    //获奖类型-video
    const getSelect=()=>{
        Ajax.POST({
          url: "/api/getSelect",
          data:{type:'video'}
        }).then((res) => {
          if (res.data.code === 10000) {
           tabList.value=res.data.data;
           tabList.value.unshift({cn_name:'全部',en_name:'All',id:''})
          }
        });
    }
     const getList=(v)=>{
       if(v){
         searchForm.per_page+=9
       }
      store.dispatch("app/getvideo_list",searchForm).then(res=>{
        listData.value=res.data;
        last_page.value=res.last_page;
      })
    }
    const change_radio=(v)=>{
      searchForm.awards_type=v;
       getList()
    }
    getList()
    getSelect()
    return {
      t,
      getters,
      tabList,
      searchForm,
      radiGroup,
      last_page,
      listData,//列表数据
      store,
      change_radio,
      go_videoDetails,
      getList
    };
  },
});
</script>
<style lang="scss" scoped>
.contentbody{
  margin:50px auto;
.video-content {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  li {
    width: 388px;
    height: 340px;
    margin-bottom: 20px;
    cursor: pointer;
    margin: 0 18px 20px 0;
    .content {
      border: 1px solid #EEEEEE;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      position: relative;
      border-radius: 2px;
      .is_top{
        position: absolute;
        top: 0px;
        width: 50px;
        height: 22px;
        font-size: 12px;
        background: #FAAD14;
        border-radius: 2px 0px 2px 0px;
        color: #FFFFFF;
        line-height: 22px;
        text-align: center;
      }
      .img-div {
        width: 100%;
        height: 244px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .title-div {
        margin: 16px 12px 20px;
        width: 365px;
        height: 60px;
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 500;
        color: #333333;
        line-height: 30px;
        display: -webkit-box;
        word-break: break-all;
        text-overflow: ellipsis;
        overflow: hidden;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
  }
  li:nth-child(3n){margin-right: 0px;}
  .content:hover {
    background: #FFFFFF;
    box-shadow: 0px 13px 20px 0px #E1E1E1;
    border-radius: 2px;
    border: 1px solid #ED3524;
  }
}
}
  @media screen and (min-width: 375px) and (max-width: 750px){
    .contentbody{
      width: 95% !important;
      :deep(.el-radio-group){
        text-align: center;
        label{margin-right: 10px;}
        .el-radio-button__inner{
          padding: 10px !important;
          font-size: 15px;
          margin-top: 10px;
          border-left: 1px solid #dcdfe6;
        }
      }
      width: 90%;
      .video-content{
        li{
           width:47.4%; height: 206px;
          .content{
            >img{top: 40px !important;left: 59px !important;}
              .img-div{
                  height: 130px !important;
                  // img{object-fit: contain;}
              }
              .title-div{width: 153px;margin: 10px 8px 14px;height: 45px;font-size: 16px;line-height: 25px;}
            }
          }
         li:nth-child(2n){margin-right: 0px !important;}
         li:nth-child(3n){margin-right: 18px;}
      }
      
    }
    .btn-more {width: 80%;}
  }
  //  @media screen and (min-width: 376px) and (max-width: 750px){
  //     // :deep(.el-radio-group){
  //     //    text-align: left !important;
  //     // }
  //  }
</style>
