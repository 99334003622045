<template>
<div>
   <div class="banner-box" v-if="adList.length>0">
        <el-carousel trigger="click" :autoplay='true' height="180px" :interval='4000'>
            <el-carousel-item v-for="item in adList" :key="item.ad_id">
             <template v-if="item.ad_jump_url">
                <a :href="item.ad_jump_url" target="_blank">
                    <img v-if="width>750" :src="item.ad_img_url" alt="">
                    <img v-else :src="item.ad_img_m_url" alt="">
                </a>
              </template>
              <template v-else>
                <img  v-if="width>750" :src="item.ad_img_url" alt="">
                <img v-else :src="item.ad_img_m_url" alt="">
              </template>
            </el-carousel-item>
        </el-carousel>
    </div>
  <div class="contentbody judge-info">
    <div class="maintitle">
      <div
        v-for="item in tabList"
        :key="item.ind"
        :class="item.ind === activeInd && 'active'"
        @click="() => tabClick(item.ind)">
        {{ getters.language.value==='cn'?item.cn_name:item.en_name }}
      </div>
    </div>
    <News v-if="activeInd === 1"/>
    <VideoPage v-else/>
  </div>
  </div>
</template>
<script>
import { defineComponent, ref, reactive } from "vue";
import News from "./components/news.vue";
import VideoPage from "./components/videoPage.vue";
import { useI18n } from 'vue-i18n'
import {useGetters} from '@/store/use.js'
import { useRouter, onBeforeRouteUpdate } from "vue-router";
import store from '@/store'
export default defineComponent({
  components: {
    News,
    VideoPage,
  },
  setup() {
    document.documentElement.scrollTop=0;
    const { t, locale } = useI18n()
    const getters=useGetters(['language'])
    const router = useRouter();
    const width=ref(document.body.clientWidth);
    const tabList = ref([
      { cn_name: "新闻动态",en_name: "News", ind: 1 },
      { cn_name: "精彩视频",en_name: "Video", ind: 2 },
    ]);
     const adList=ref([])
     store.dispatch('app/getad_list', {num:4,type:'information_head'}).then(res=>{
          adList.value=JSON.parse(sessionStorage.getItem('information_head'))
      });
    const query = router.currentRoute.value.query;
    const activeInd = ref(Number(sessionStorage.getItem('ind'))||1);
    const tabClick = (ind) => {
        sessionStorage.removeItem('ind')
        activeInd.value = ind;
        store._state.data.app.yearType = ind;
    }
    window.onresize = () => {
        return (() => {
          width.value=document.body.clientWidth;
        })();
      };
    return {
      t,
      getters,
      tabList,
      activeInd,
      tabClick,
      adList,
       width
    };
  },
});
</script>
<style lang="scss">
 .banner-box{
      width: 100%;
      text-align: center;
      img{
          width: 100%;
          height: 100%;
          // object-fit: cover;
      }          
  }
.judge-info {
    margin: 50px auto 0;
    min-height: 500px;
  .maintitle {
    display: flex;
    justify-content: center;
    > div {
      padding: 0 48px;
      // border-right: 1px solid #eeeeee;
      font-size: 30px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #222222;
      height: 42px;
      line-height: 42px;
      cursor: pointer;
    }
    // > div:nth-last-of-type(1) {
    //   border-right: none;
    // }
    .active {
      color: #e34c3e;
      position: relative;
    }
    .active:after {
      content: "";
      width: 118px;
      height: 5px;
      background: #e34c3e;
      position: absolute;
      bottom: -16px;
      left: 36%;
      margin-left: -30px;
    }
  }
  .btn-more {
    // margin-top: 45px;
    width: 600px;
    height: 56px;
    color: #fff;
    font-size: 16px;
    background: #ed3524;
    border-radius: 1px;
    border: 1px solid #ed3524;
    cursor: pointer;
  }
}
 @media screen and (min-width: 375px) and (max-width: 750px){
    .contentbody{
     
      width: 100%;
      margin: 30px auto 0;
      .maintitle >div{padding: 0 20px;}
      .btn-more {width: 90%;}
    }
  }
</style>
